import React, { useState } from "react";
import { Router } from "@reach/router";
import {
  PrivacySection,
  QuoteLayout,
  Thankyou,
} from "../../components/utility/quote";
import { Product_Quote_Routes } from "../../data/Routes";
import SecurityContext from "../../contexts/securityContext";
import firebase from "gatsby-plugin-firebase";
import Question1 from "./Question1";
import Question2 from "./Question2";
import Form from "./Form";
import { ServicesMenuSection } from "../../components/containers/ServicesMenuSection";
import { Footer } from "../../components/utility";
import GtagCode from "../../data/GtagCode";
const Quote = () => {
  const [que1, setQue1] = useState(null);
  const [que2, setQue2] = useState(null);
  const [formValues, setFormValues] = useState(null);

  const addLeadToBackend = async (values) => {
    await fetch("/.netlify/functions/sendgrid", {
      method: "POST",
      body: JSON.stringify({
        email: "hitesh.patel@icthub.com.au",
        subject: "New lead recived!!",
        name: values.fullname,
      }),
    });
    var timestamp = firebase.firestore.FieldValue.serverTimestamp();
    await firebase.firestore().collection("Cyber_Security").add({
      customerDetails: values,
      "What type of security solution do you need?": que1,
      "How many users do you have?": que2,
      Lead_Generated: timestamp,
    });
    try {
      const response = fetch("/.netlify/functions/sendgrid", {
        method: "POST",
        body: JSON.stringify({
          email: values.email,
          subject: "Digital Comparison",
          name: values.fullname,
        }),
      });
      if (!response.ok) {
        //not 200 response
        return;
      }
    } catch (e) {
      //error
      console.log(e);
    }
  };

  return (
    <QuoteLayout>
      <GtagCode />
      <SecurityContext.Provider
        value={{
          que1,
          setQue1,
          que2,
          setQue2,
          formValues,
          setFormValues,
          addLeadToBackend,
        }}
      >
        <Router>
          <section path={Product_Quote_Routes.CyberSecurity}>
            <Question1 title="IT Cyber Security" path="/" />
            <Question2 title="IT Cyber Security" path="/2" />
            <Form title="IT Cyber Security" path="/3" />
            <Thankyou title="IT Cyber Security" path="/thankyou" />
          </section>
        </Router>
      </SecurityContext.Provider>
      <PrivacySection /> <ServicesMenuSection />
      <Footer />
    </QuoteLayout>
  );
};

export default Quote;
